const jwt = require("jsonwebtoken");
const encrypt = require("../encrypt/encrypt");
const authControl = require("../auth/auth");
var decrypt_rs, encrypt_jwt_token, user
const checkAuth = () => {
  var [p1, p2, p3] = authControl.loadAuth()
  decrypt_rs = p1
  encrypt_jwt_token = p2
  user = p3
}

const rolesAll = async (b) => {
  var result = { data: null, tp: 1 };
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "roles-all"
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1,
        p2: b.p2,
        p3: b.p3,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message === "get data Success") {
          const decrypted = encrypt.cryptr_decrypt().decrypt(data.token);
          const rs_d = jwt.decode(decrypted);
          result.data = rs_d.data;
          result.tp = rs_d.total_pages;
        } else if (data.message === "data empty") {
          result = { data: null, tp: 1 };
        } else if (data.error === "jwt expired") {
          result = { data: 'jwt expired', tp: 1 };
        }
      });
    return result;
  } catch (e) {
    return e.code
  }
}

const createRole = async (b) => {
  var result = "";
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "role-create"
    await fetch(url, {
      method: "POST",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1,
        p2: b.p2,
        p3: b.p3,
        p4: b.p4,
        created_by: user
      }),
    })
      .then(response => response.json())
      .then(data => {
        result = data.message
      });
    return result;
  } catch (e) {
    return e.code
  }
}

const deleteRole = async (b) => {
  var result = "";
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "role-delete"
    await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1
      }),
    })
      .then(response => response.json())
      .then(data => {
        result = data.message;
      });
    return result;
  } catch (e) {
    return e.code
  }
}

const updateRole = async (b) => {
  var result = "";
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "role-update"
    await fetch(url, {
      method: "PATCH",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }, body: JSON.stringify({
        p1: b.p1,
        p2: b.p2,
        p3: b.p3,
        p4: b.p4,
        p5: b.p5,
        update_by: user
      }),
    })
      .then(response => response.json())
      .then(data => {
        result = data.message
      });
    return result;
  } catch (e) {
    return e.code
  }
}

const getRolesForOptions = async () => {
  let result = null;
  try {
    checkAuth()
    var url = process.env.REACT_APP_API_URL + "roles-for-opt"
    await fetch(url, {
      method: "GET",
      headers: {
        Authorization: encrypt_jwt_token,
        Accept: "application/json",
        "Content-Type": "application/json",
        AppMode: 'core',
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.message == "get data Success") {
          const decrypted = encrypt.cryptr_decrypt().decrypt(data.token)
          const rs_d = jwt.decode(decrypted);
          result = rs_d.data;
        }
      });
    return result;
  } catch (e) {
    return e.code;
  }
}

const getRoles = () => {
  let role = []
  if (localStorage.getItem("authUser") != null) {
    if (JSON.parse(localStorage.getItem("authUser")).token != undefined &&
      JSON.parse(localStorage.getItem("authUser")).token != null && JSON.parse(localStorage.getItem("authUser")).token != '') {
      const decrypted = encrypt.cryptr_decrypt().decrypt(JSON.parse(localStorage.getItem("authUser")).token);
      const rs_d = jwt.decode(decrypted);
      role = rs_d.role.split(',');
    }
  }
  return role
}

export {
  rolesAll,
  createRole,
  deleteRole,
  updateRole,
  getRolesForOptions,
  getRoles
}
