import { IsNotNull } from '../../controllers/commons/helper';
const encrypt = require("../encrypt/encrypt");
const jwt = require("jsonwebtoken");
const loadAuth = () => {
  var encrypt_jwt_token, decrypt_rs, user
  const token_ls = JSON.parse(localStorage.getItem("authUser"));
  if (IsNotNull(token_ls)) {
    let tkn = token_ls.token
    if (IsNotNull(tkn)) {
      let decrypted = encrypt.cryptr_decrypt().decrypt(tkn)
      let rs_d = jwt.decode(decrypted);
      if (rs_d.exp * 1000 < Date.now()) {
        localStorage.clear();
        window.location.href = `${process.env.REACT_APP_DOMAIN}`
      } else {
        decrypt_rs = encrypt.cryptr_decrypt().decrypt(token_ls.token);
        encrypt_jwt_token = encrypt.cryptr_encrypt().encrypt(decrypt_rs);
        user = token_ls.user
      }
    } else {
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_DOMAIN}`
    }
  }
  return [decrypt_rs, encrypt_jwt_token, user]
};

export { loadAuth }
